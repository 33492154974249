import React from 'react';
import SEO from 'components/seo';

// Component
import Hero from 'components/molecules/Hero/Hero';
import TextBlock from 'components/organisms/Blocks/TextBlock/TextBlock';
import TextImageBlock from 'components/organisms/Blocks/TextImageBlock/TextImageBlock';
import ImageOverview from 'components/organisms/Blocks/ImageOverview/ImageOverview';

// Data
import VloerenData from 'data/VloerenData/VloerenData.json';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Hero
      {...VloerenData.hero}
    />
    <TextBlock
      data={VloerenData.overOns}
    />
    <TextImageBlock
      data={VloerenData.woonvloeren}
      themeColor="grey-b"
    />
    <TextImageBlock
      data={VloerenData.bedrijfsvloeren}
    />
    <TextImageBlock
      data={VloerenData.terrasvloeren}
      themeColor="grey-b"
    />
    <ImageOverview
      data={VloerenData.recenteProjecten}
      imagesPerRow={3}
    />
    <Hero
      modifier="inPage"
      {...VloerenData.inPageHero}
    />
  </>
);

export default IndexPage;
